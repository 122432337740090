import HttpHelper from "../../helpers/httpHelper";
import { getTransactionUrl, getPriceAPIUrl, getTransferCoinUrl, createOrderUrl, getTokenBalanceUrl, updateTokensUrl, getTransferTokensUrl } from "./transaction.uri";

class TransactionAPI {
    static async GetTransactions(type) {
        let url = getTransactionUrl(type);
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async GetTokenBalances(tokenAddresses) {
        let url = getTokenBalanceUrl();
        return HttpHelper.httpRequest(url, 'POST', {}, {
            tokens: tokenAddresses
        });
    }

    static async GetPrices() {
        let url = getPriceAPIUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async TransferCoins(req) {
        let url = getTransferCoinUrl(req.address, req.type, req.coins);
        if (req.tokenAddress) {
            url = getTransferTokensUrl(req.address, req.type, req.coins, req.tokenAddress);
        }
        return HttpHelper.httpRequest(url, 'POST', {});
    }

    static async CreateOrder(req) {
        let url = createOrderUrl();
        return HttpHelper.httpRequest(url, 'POST', {}, req);
    }
}

export default TransactionAPI;
