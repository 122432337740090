export const TransactionActionTypes = {
    FETCH_TRANSACTION_REQUEST: '@@transaction/FETCH_TRANSACTION_REQUEST',
    FETCH_TRANSACTION_SUCCESS: '@@transaction/FETCH_TRANSACTION_SUCCESS',
    FETCH_TRANSACTION_FAILURE: '@@transaction/FETCH_TRANSACTION_FAILURE',

    FETCH_PRICES_REQUEST: '@@transaction/FETCH_PRICES_REQUEST',
    FETCH_PRICES_SUCCESS: '@@transaction/FETCH_PRICES_SUCCESS',
    FETCH_PRICES_FAILURE: '@@transaction/FETCH_PRICES_FAILURE',

    TRANSFER_COINS_REQUEST: '@@transaction/TRANSFER_COINS_REQUEST',
    TRANSFER_COINS_SUCCESS: '@@transaction/TRANSFER_COINS_SUCCESS',
    TRANSFER_COINS_FAILURE: '@@transaction/TRANSFER_COINS_FAILURE',

    CREATE_ORDER_REQUEST: '@@transaction/CREATE_ORDER_REQUEST',
    CREATE_ORDER_SUCCESS: '@@transaction/CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAILURE: '@@transaction/CREATE_ORDER_FAILURE',
}

export const transactionInitialState = {
    transactions: [],
    prices: [],
    orders: [],
    isLoading: false
}