import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import ResponsiveDrawer from "../common/header";
import { getAccessToken, isVerifiedUser } from "../store/auth/auth.selector";
import { IsNullOrEmpty } from "./utility";
import { getUser } from "../store/user/user.selector";
import SetPin from "../pages/setPin";
import VerifyPin from "../common/verifyPin";
import VerifyPage from "../pages/verify";

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const { accessToken, user, verifiedUser } = useSelector((state) => {
        return {
            accessToken: getAccessToken(state),
            user: getUser(state),
            verifiedUser: isVerifiedUser(state)
        }
    })

    if (IsNullOrEmpty(accessToken) && IsNullOrEmpty(user.id)) {
        return (
            <Navigate
                to={
                    "/login#" +
                    location.pathname +
                    location.search +
                    location.hash
                }
                state={{ lastPage: location.pathname }}
            />
        );
    } else if (!IsNullOrEmpty(accessToken) && !verifiedUser && location.pathname !== "/onboarding") {
        return <SetPin />;
    } else if (!IsNullOrEmpty(accessToken) && verifiedUser && IsNullOrEmpty(user.id) && location.pathname !== "/verify") {
        return <VerifyPage />
    }
    else if (!IsNullOrEmpty(accessToken) && !IsNullOrEmpty(user.id)) {
        return (
            <ResponsiveDrawer>
                {children}
            </ResponsiveDrawer>
        );
    }
};

export default ProtectedRoute;
