import { WithdrawActionTypes, withdrawInitialState } from "./withdraw.model";

const reducer = (state = withdrawInitialState, action) => {
  switch (action.type) {
    case WithdrawActionTypes.FETCH_WITHDRAW_REQUEST:
    case WithdrawActionTypes.RESTART_WITHDRAW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case WithdrawActionTypes.FETCH_WITHDRAW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        withdraws: action.payload,
      };

    case WithdrawActionTypes.RESTART_WITHDRAW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        withdraws: state.withdraws.map((t) => {
          if (t.id === action.payload.id) {
            return action.payload;
          }
          return t;
        }),
      };

    case WithdrawActionTypes.FETCH_WITHDRAW_FAILURE:
    case WithdrawActionTypes.RESTART_WITHDRAW_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export { reducer as WithdrawReducer };

