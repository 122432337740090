import { call, put, takeEvery } from 'redux-saga/effects';
import * as SupportActions from './support.action';
import SupportAPI from './support.api';
import { SupportActionTypes } from './support.model';

export function* handleFetchSupport(action) {
    try {
        const response = yield call(SupportAPI.GetSupports, action.payload);
        yield put(SupportActions.fetchSupportSuccess(response));
    } catch (e) {
        yield put(SupportActions.fetchSupportFailure(e));
    }
}

export function* handleCreateSupport(action) {
    try {
        const response = yield call(SupportAPI.CreateSupport, action.payload);
        yield put(SupportActions.createSupportSuccess(response));
    } catch (e) {
        yield put(SupportActions.createSupportFailure(e));
    }
}

export function* SupportSaga() {
    yield takeEvery(SupportActionTypes.FETCH_SUPPORT_REQUEST, handleFetchSupport);
    yield takeEvery(SupportActionTypes.CREATE_SUPPORT_REQUEST, handleCreateSupport);
}
