import { action } from "typesafe-actions";
import { TransactionActionTypes } from "./transaction.model";

export const fetchTransactionRequest = (req) => action(TransactionActionTypes.FETCH_TRANSACTION_REQUEST, req);
export const fetchTransactionSuccess = (res) => action(TransactionActionTypes.FETCH_TRANSACTION_SUCCESS, res);
export const fetchTransactionFailure = (err) => action(TransactionActionTypes.FETCH_TRANSACTION_FAILURE, err);

export const fetchPricesRequest = (req) => action(TransactionActionTypes.FETCH_PRICES_REQUEST, req);
export const fetchPricesSuccess = (res) => action(TransactionActionTypes.FETCH_PRICES_SUCCESS, res);
export const fetchPricesFailure = (err) => action(TransactionActionTypes.FETCH_PRICES_FAILURE, err);

export const transferCoinRequest = (req) => action(TransactionActionTypes.TRANSFER_COINS_REQUEST, req);
export const transferCoinSuccess = (res) => action(TransactionActionTypes.TRANSFER_COINS_SUCCESS, res);
export const transferCoinFailure = (err) => action(TransactionActionTypes.TRANSFER_COINS_FAILURE, err);

export const createOrderRequest = (req) => action(TransactionActionTypes.CREATE_ORDER_REQUEST, req);
export const createOrderSuccess = (res) => action(TransactionActionTypes.CREATE_ORDER_SUCCESS, res);
export const createOrderFailure = (err) => action(TransactionActionTypes.CREATE_ORDER_FAILURE, err);



