import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCCADEDLLNjnfaISEnHBNbFEhZfudazBlU",
    authDomain: "crypto-wallet-payment.firebaseapp.com",
    projectId: "crypto-wallet-payment",
    storageBucket: "crypto-wallet-payment.appspot.com",
    messagingSenderId: "194390069826",
    appId: "1:194390069826:web:5406dcd7e2f8d468a553db"
};

const app = initializeApp(firebaseConfig);
const defaultAuth = getAuth(app);
const defaultProvider = new GoogleAuthProvider();
export { defaultAuth, defaultProvider };