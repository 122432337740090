import { action } from "typesafe-actions";
import { WithdrawActionTypes } from "./withdraw.model";

export const fetchWithdrawRequest = (req) => action(WithdrawActionTypes.FETCH_WITHDRAW_REQUEST, req);
export const fetchWithdrawSuccess = (res) => action(WithdrawActionTypes.FETCH_WITHDRAW_SUCCESS, res);
export const fetchWithdrawFailure = (err) => action(WithdrawActionTypes.FETCH_WITHDRAW_FAILURE, err);

export const restartWithdrawRequest = (req) => action(WithdrawActionTypes.RESTART_WITHDRAW_REQUEST, req);
export const restartWithdrawSuccess = (res) => action(WithdrawActionTypes.RESTART_WITHDRAW_SUCCESS, res);
export const restartWithdrawFailure = (err) => action(WithdrawActionTypes.RESTART_WITHDRAW_FAILURE, err);

