import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WalletCard from "../common/walletCard";
import WalletList from "../common/walletList";
import WalletTransaction from "../common/walletTransaction";
import { getUserInfo } from "../store/auth/auth.selector";
import { fetchTransactionRequest } from "../store/transaction/transaction.action";
import { getCoinPrices } from "../store/transaction/transaction.selector";
import { fetchCoinBalanceRequest } from "../store/user/user.action";
import { getCoinBalance, getUser } from "../store/user/user.selector";
export const pageSize = 9;

function MyWallets() {
  const dispatch = useDispatch();
  const [coinBalance, setCoinBalance] = useState({
    "XRP": 0,
    "BTC": 0,
    "BNB": 0,
    "LTC": 0,
    "BUSD": 0,
    "USDT": 0,
    "ETH": 0,
    "TRX": 0,
  });
  const [currentBalance, setCurrentBalance] = useState(0);
  const [selectedItem, setSelectedItem] = useState("");

  const { userInfo, user, prices, balance } = useSelector((state) => {
    return {
      userInfo: getUserInfo(state),
      user: getUser(state),
      balance: getCoinBalance(state),
      prices: getCoinPrices(state)
    }
  });

  useEffect(() => {
    if (selectedItem) {
      dispatch(fetchTransactionRequest(selectedItem));
      setCurrentBalance(coinBalance[selectedItem] || 0);
    }
  }, [selectedItem])

  useEffect(() => {
    if (prices.length > 0) {
      dispatch(fetchCoinBalanceRequest());
    }
  }, [prices]);

  useEffect(() => {
    if (balance.length > 0) {
      var x = {};
      balance.forEach(element => {
        x[element.coinType] = parseFloat(element.coinBalance);
      });
      setCoinBalance(x);
    }
  }, [balance]);

  if (selectedItem) {
    return <div>
      <WalletCard
        selectedItem={selectedItem}
        onClose={() => setSelectedItem("")}
        coinBalance={coinBalance}
        currentBalance={currentBalance} />
      <WalletTransaction selectedItem={selectedItem} />
    </div>
  }

  return (
    <Card variant="outlined">
      <CardHeader avatar={<img src={userInfo.user.photoURL} />}
        subheader="Wallet Information" title={userInfo.user.displayName} />
      <Divider />
      <CardContent>
        <WalletList coinBalance={coinBalance} onChange={(item) => setSelectedItem(item)} />
      </CardContent>
    </Card>
  );
}

export default MyWallets;
