import { Poll } from "@mui/icons-material";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useSelector } from "react-redux";
import _ from "underscore";
import Stats from "../common/stats";
import { getCoinPrices } from "../store/transaction/transaction.selector";
import { calculateData } from "../helpers/utility"
export const pageSize = 9;

function WalletChart(props) {
    const { coinBalance } = props;

    const { prices } = useSelector((state) => {
        return {
            prices: getCoinPrices(state)
        }
    });


    return (
        <>
            {
                prices && prices.length > 0 &&
                <Card elevation={10}>
                    <CardHeader avatar={<Poll color="primary" />} title="Wallets" subheader="Statistics">
                    </CardHeader>
                    <CardContent style={{ position: "relative" }}>
                        <Stats data={calculateData(prices, coinBalance)} field="coin" value="val" title="Coins" />
                    </CardContent>
                </Card>
            }
        </>
    );
}

export default WalletChart;
