import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      'sans-serif'
    ].join(','),
    fontWeightBold: 700,
    fontWeightLight: 400,
    fontWeightMedium: 600,
    fontWeightRegular: 500
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize"
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          border: "1px solid #3A98B9",
          color: "#3A98B9"
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#3A98B9',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFB84C',
      contrastText: "#FFFFFF"
    },
    success: {
      main: '#edffea',
      dark: "#79d70f"
    },
    info: {
      main: '#00aced',
    },
    error: {
      main: '#c70039',
    },
    divider: '#bfbdbd',
    background: {
      default: '#fff',
    },
  },
});

export default theme;
