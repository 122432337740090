import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundPage from './common/NotFound';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import ProtectedRoute from './helpers/privateRoute';
import VerifyPin from './pages/verify';
import SetPin from './pages/setPin';
import Profile from './pages/profile';
import Wallets from './pages/wallets';
import Deposit from './pages/deposit';
import Withdraw from './pages/withdraw';
import Transaction from './pages/transaction';
import Whitelist from './pages/whitelist';
import Settings from './pages/settings';
import Support from './pages/support';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/verify" element={
          <ProtectedRoute>
            <VerifyPin />
          </ProtectedRoute>} />
        <Route exact path="/onboarding" element={
          <ProtectedRoute>
            <SetPin />
          </ProtectedRoute>} />
        <Route exact path="/" element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>} />
        <Route exact path="/profile" element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>} />
        <Route exact path="/wallets" element={
          <ProtectedRoute>
            <Wallets />
          </ProtectedRoute>} />
        <Route exact path="/deposits" element={
          <ProtectedRoute>
            <Deposit />
          </ProtectedRoute>} />
        <Route exact path="/withdraws" element={
          <ProtectedRoute>
            <Withdraw />
          </ProtectedRoute>} />
        <Route exact path="/transactions" element={
          <ProtectedRoute>
            <Transaction />
          </ProtectedRoute>} />
        <Route exact path="/whitelists" element={
          <ProtectedRoute>
            <Whitelist />
          </ProtectedRoute>} />
        <Route exact path="/settings" element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>} />
        <Route exact path="/support" element={
          <ProtectedRoute>
            <Support />
          </ProtectedRoute>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
