import { call, put, takeEvery } from 'redux-saga/effects';
import * as DepositActions from './deposit.action';
import DepositAPI from './deposit.api';
import { DepositActionTypes } from './deposit.model';

export function* handleFetchDeposit(action) {
    try {
        const response = yield call(DepositAPI.GetDeposits, action.payload);
        yield put(DepositActions.fetchDepositSuccess(response));
    } catch (e) {
        yield put(DepositActions.fetchDepositFailure(e));
    }
}
export function* DepositSaga() {
    yield takeEvery(DepositActionTypes.FETCH_DEPOSIT_REQUEST, handleFetchDeposit);
}
