import { Add, FilterList, ListAlt, Search, Support as SupportIcon, Visibility } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, Grid, InputAdornment, Menu, MenuItem, Pagination, Select, TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { IsNullOrEmpty, getLogo, pageSize, paginate } from "../helpers/utility";
import { createSupportRequest, fetchSupportRequest } from "../store/support/support.action";
import { getSupports } from "../store/support/support.selector";

function Support() {
    const dispatch = useDispatch();
    const [issueType, setIssueType] = useState("");
    const [referenceNo, setReferenceNo] = useState("");
    const [description, setDescription] = useState("");
    const [isValidated, setValidated] = useState(false);

    const [mode, setMode] = useState("list");
    const [status, setStatus] = useState("Requested");
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [filter, setFilter] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [datum, setDatum] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (sta) => {
        if (sta) {
            setStatus(sta);
        }
        setAnchorEl(null);
    };

    const validate = () => {
        return !IsNullOrEmpty(issueType) &&
            !IsNullOrEmpty(description);
    }

    const onSave = () => {
        if (validate()) {
            dispatch(createSupportRequest({
                issueType: issueType,
                referenceNo: referenceNo,
                description: description
            }))
        }
        else {
            setValidated(true);
        }
    }

    useEffect(() => {
        dispatch(fetchSupportRequest());
    }, []);

    const { supports } = useSelector((state) => {
        return {
            supports: getSupports(state)
        }
    });

    useEffect(() => {
        setReferenceNo("");
        setDescription("");
        setIssueType("");
        setMode("list");
    }, [supports])

    let filteredList = supports;
    if (!filteredList) {
        filteredList = [];
    }

    if (!IsNullOrEmpty(searchText)) {
        filteredList = filteredList.filter((x) =>
            x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            x.cid.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            x.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            x.txnid.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            x.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    }

    if (!IsNullOrEmpty(filter)) {
        let startTime = moment().startOf('day');
        let endTime = moment().endOf('day');
        switch (filter) {
            case "Today":
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
            case "Week":
                startTime = moment().startOf("weeks");
                endTime = moment().endOf('weeks');
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
            case "Month":
                startTime = moment().startOf('month');
                endTime = moment().endOf('month');
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
            case "Year":
                startTime = moment().startOf('year');
                endTime = moment().endOf('year');
                filteredList = filteredList.filter((x) => {
                    return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
                })
                break;
        }
    }

    filteredList = _.sortBy(filteredList, "createdOn").reverse();
    let totalPages = Math.ceil(filteredList.length / pageSize);
    filteredList = paginate(filteredList, pageSize, page);

    return <Card variant="outlined">
        <CardHeader
            avatar={<SupportIcon />}
            title="Support"
            subheader={mode === "list" ? "Registered Tickets" : "Add New Ticket"}
            action={<Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                    setMode(mode === "add" ? "list" : "add")
                }}
                startIcon={mode === "list" ? <Add /> : <ListAlt />}>
                {
                    mode === "list" ? "Add New" : "View All"
                }
            </Button>}
        />
        <Divider />
        <CardContent>
            {
                mode === "list" ? <Grid container spacing={2} className="my-2">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box display={"flex"} width="100%" justifyContent={"space-between"} alignContent={"center"}>
                            <div>
                                <TextField
                                    variant="outlined"
                                    className="rounded-lg"
                                    style={{ marginTop: 0 }}
                                    margin="dense"
                                    size="small"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    }}
                                    placeholder="Search..."
                                />
                                <FilterList
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className="m-1"
                                />
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => handleClose()}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => handleClose("Requested")}>Requested</MenuItem>
                                    <MenuItem onClick={() => handleClose("Approved")}>Approved</MenuItem>
                                    <MenuItem onClick={() => handleClose("Failed")}>Failed</MenuItem>
                                </Menu>
                            </div>
                            <Select
                                native
                                margin="dense"
                                size="small"
                                variant="outlined"
                                value={filter}
                                onChange={(e) => [
                                    setFilter(e.target.value)
                                ]}
                            >
                                <option value="">All Time</option>
                                <option value="Today">Today</option>
                                <option value="Week">This Week</option>
                                <option value="Month">This Month</option>
                                <option value="Year">This Year</option>
                            </Select>
                        </Box>
                        {/* <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    }}
                  />
                  <Chip variant={status === "Requested" ? "filled" : "outlined"} color="primary" label="Requested" className="mx-2" onClick={() => setStatus("Requested")} />
                  <Chip variant={status === "Approved" ? "filled" : "outlined"} color="primary" label="Approved" className="mx-2" onClick={() => setStatus("Approved")} />
                  <Chip variant={status === "Failed" ? "filled" : "outlined"} color="primary" label="Failed" className="mx-2" onClick={() => setStatus("Failed")} /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                            {
                                filteredList.map((dep) => {
                                    return <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Card variant="outlined">
                                            <CardHeader
                                                avatar={<img style={{ height: 32, width: 32 }}
                                                    src={getLogo(dep.type).logo} />}
                                                classes={{
                                                    root: "p-1",
                                                    action: "px-3 py-2"
                                                }}
                                                title={dep.cid}
                                                subheader={dep.name}
                                                action={
                                                    <Visibility
                                                        color="secondary"
                                                        onClick={() => setSelectedItem(dep.id)}
                                                    />
                                                }
                                            />
                                            <Divider />
                                            <CardContent className="p-0">
                                                <table className="table-striped text-medium border-none">
                                                    <tr>
                                                        <td>Amount</td>
                                                        <td>{`${dep.tokenSymbol || dep.type} ${dep.coins}`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Coins</td>
                                                        <td>{`${dep.tokenSymbol || dep.type} ${dep.transactionCharge}`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Transaction Time</td>
                                                        <td>{moment.unix(dep.createdOn._seconds).format("lll")}</td>
                                                    </tr>
                                                </table>
                                            </CardContent>
                                            <CardActions>
                                                <Button variant="contained" fullWidth color="primary" size="small" disableElevation>
                                                    {dep.status}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={12}>
                        {
                            filteredList.length > 0 &&
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                <Pagination
                                    shape="rounded"
                                    variant="outlined"
                                    count={totalPages}
                                    page={page}
                                    color="primary"
                                    onChange={(e, pg) => {
                                        setPage(pg)
                                    }} />
                            </Box>
                        }
                    </Grid>
                </Grid> :
                    <Container maxWidth="md">
                        <Select
                            native
                            fullWidth
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={issueType}
                            onChange={(e) => [
                                setIssueType(e.target.value)
                            ]}
                            error={isValidated && IsNullOrEmpty(issueType)}
                        >
                            <option value="">Select</option>
                            <option value="Account">Account</option>
                            <option value="Deposit">Deposit</option>
                            <option value="Withdraw">Withdraw</option>
                            <option value="Payment Gateway">Payment Gateway</option>
                            <option value="Buy Order">Buy Order</option>
                            <option value="Sell Order">Sell Order</option>
                        </Select>
                        <TextField
                            variant="outlined"
                            fullWidth
                            className="rounded-lg"
                            margin="dense"
                            size="small"
                            value={referenceNo}
                            onChange={(e) => {
                                setReferenceNo(e.target.value)
                            }}
                            placeholder="Enter Reference No"
                            label="Reference No"
                        />
                        <TextField
                            multiline
                            fullWidth
                            minRows={3}
                            variant="outlined"
                            className="rounded-lg"
                            margin="dense"
                            size="small"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                            placeholder="Enter Description"
                            label="Description"
                            error={isValidated && IsNullOrEmpty(description)}
                            helperText={isValidated && IsNullOrEmpty(description) ? "Please enter description" : ""}
                        />
                        <Button variant="contained" onClick={() => onSave()} color="secondary" className="float-right my-2">
                            Submit
                        </Button>
                    </Container>
            }
        </CardContent>
    </Card>
}

export default Support;