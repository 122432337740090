import HttpHelper from "../../helpers/httpHelper";
import { getWhitelistUrl } from "./whitelist.uri";

class WhitelistAPI {
    static async GetWhitelists() {
        let url = getWhitelistUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default WhitelistAPI;
