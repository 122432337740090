import { AccountBalance, AccountBox, CurrencyExchange, Dashboard, ExitToApp, Key, Settings, TransferWithinAStation, Wallet } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom/dist';
import { clearToken } from '../helpers/utility';
import { fetchPricesRequest } from '../store/transaction/transaction.action';
import { getStatisticsRequest, logout } from '../store/user/user.action';

const drawerWidth = 240;

export default function ResponsiveDrawer(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { window, children } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    React.useEffect(() => {
        dispatch(getStatisticsRequest());
        dispatch(fetchPricesRequest());
      }, [dispatch])    

    const drawer = (
        <div>
            <img src={process.env.PUBLIC_URL + "images/logo.png"} />
            <Divider />
            <List>
                <ListItem disablePadding onClick={() => navigate("/")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Dashboard />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/profile")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccountBox />
                        </ListItemIcon>
                        <ListItemText primary={"My Profile"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/wallets")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Wallet />
                        </ListItemIcon>
                        <ListItemText primary={"My Wallets"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/deposits")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccountBalance />
                        </ListItemIcon>
                        <ListItemText primary={"Deposits"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/withdraws")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <TransferWithinAStation />
                        </ListItemIcon>
                        <ListItemText primary={"Withdraws"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/transactions")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <CurrencyExchange />
                        </ListItemIcon>
                        <ListItemText primary={"Transactions"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/whitelists")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Key />
                        </ListItemIcon>
                        <ListItemText primary={"Whitelist Wallets"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/settings")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText primary={"Settings"} />
                    </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding onClick={() => navigate("/support")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Support />
                        </ListItemIcon>
                        <ListItemText primary={"Support"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/orders")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ShoppingBag />
                        </ListItemIcon>
                        <ListItemText primary={"My Orders"} />
                    </ListItemButton>
                </ListItem> */}
                <ListItem disablePadding onClick={() => {
                    dispatch(logout());
                    clearToken();
                }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Wallet Payment
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >

                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
}