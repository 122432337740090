import {
  Close,
  Height,
  Lock,
  QrCode,
  Save,
  Visibility,
  VisibilityOff,
  WaterDrop,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/loader";
import VerifyPin from "../common/verifyPin";
import { getUserInfo } from "../store/auth/auth.selector";
import {
  updateKeyRequest,
  updateSettingsRequest,
} from "../store/user/user.action";
import { getUser } from "../store/user/user.selector";

function Settings() {
  const dispatch = useDispatch();
  const [BNBMaxWithdrawal, setBNBMaxWithdrawal] = useState(0);
  const [TRXMaxWithdrawal, setTRXMaxWithdrawal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [accessKey, setAccessKey] = useState("");
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [coolingPeriod, setCoolingPeriod] = useState(-1);
  const [domain, setDomain] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const { user, userInfo, isLoading } = useSelector((state) => {
    return {
      user: getUser(state),
      userInfo: getUserInfo(state),
      isLoading: state.UserReducer.isLoading,
    };
  });

  useEffect(() => {
    if (user.BNBMaxWithdrawal) {
      setBNBMaxWithdrawal(user.BNBMaxWithdrawal);
    }
    if (user.TRXMaxWithdrawal) {
      setTRXMaxWithdrawal(user.TRXMaxWithdrawal);
    }
    if (user.accessKey) {
      setAccessKey(user.accessKey);
    }
    if (user.coolingPeriod) {
      setCoolingPeriod(user.coolingPeriod);
    }
    if (user.tenantWebsite) {
      setDomain(user.tenantWebsite);
    }
    if (user.coins) {
      setSelectedCoins(user.coins);
    }
  }, [user]);

  const handleReset = () => {
    if (user.BNBMaxWithdrawal) {
      setBNBMaxWithdrawal(user.BNBMaxWithdrawal);
    }
    if (user.TRXMaxWithdrawal) {
      setTRXMaxWithdrawal(user.TRXMaxWithdrawal);
    }
    if (user.accessKey) {
      setAccessKey(user.accessKey);
    }
    if (user.coolingPeriod) {
      setCoolingPeriod(user.coolingPeriod);
    }
    if (user.tenantWebsite) {
      setDomain(user.tenantWebsite);
    }
    if (user.coins) {
      setSelectedCoins(user.coins);
    }
  };

  const regenerate = () => {
    setId("key");
    setOpen(true);
  };

  const handleSave = () => {
    setId("settings");
    setOpen(true);
  };

  if (open) {
    return (
      <VerifyPin
        id={id}
        onClose={() => {
          setOpen(false);
        }}
        onComplete={(ref) => {
          if (ref === "settings") {
            dispatch(
              updateSettingsRequest({
                coins: selectedCoins,
                TRXMaxWithdrawal: TRXMaxWithdrawal,
                BNBMaxWithdrawal: BNBMaxWithdrawal,
                coolingPeriod: coolingPeriod,
                tenantWebsite: domain,
              })
            );
          }
          if (ref === "key") {
            dispatch(updateKeyRequest());
          }
          setOpen(false);
        }}
      />
    );
  }

  return (
    <Container maxWidth="md">
      {isLoading && <Loader />}
      <Card elevation={10}>
        <CardHeader
          avatar={<Avatar src={userInfo.user.photoURL}></Avatar>}
          subheader="Settings"
          title={userInfo.user.displayName}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <TextField
                label="Withdraw Commission"
                variant="outlined"
                fullWidth
                value={user.WithdrawCommision}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Height />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <TextField
                label="Deposit Commission"
                variant="outlined"
                fullWidth
                value={user.DepositCommision}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Height />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <TextField
                label="Merchant Key"
                variant="outlined"
                fullWidth
                disabled
                value={accessKey}
                type={visible ? "text" : "password"}
                onChange={(e) => {
                  setAccessKey(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <QrCode />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setVisible(!visible)}
                    >
                      {visible ? <VisibilityOff /> : <Visibility />}
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          regenerate();
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Generate New
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <TextField
                label="Cooling Period"
                variant="outlined"
                fullWidth
                type="number"
                value={coolingPeriod}
                helperText="For immediate withdraw please enter -1"
                onChange={(e) => {
                  setCoolingPeriod(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WaterDrop />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">Hours</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <TextField
                label="Whitelist Domain"
                variant="outlined"
                fullWidth
                value={domain}
                onChange={(e) => {
                  setDomain(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <p>Selected Coins</p>
              {[
                "TRX",
                "BTC",
                "BCH",
                "LTC",
                "BNB",
                "XRP",
                "ETH",
                "USDT",
                "BUSD",
              ].map((s) => {
                return (
                  <Chip
                    onClick={() => {
                      if (selectedCoins.indexOf(s) > -1) {
                        setSelectedCoins(selectedCoins.filter((x) => x !== s));
                      } else {
                        setSelectedCoins([...selectedCoins, s]);
                      }
                    }}
                    label={s}
                    variant={
                      selectedCoins.indexOf(s) > -1 ? "contained" : "outlined"
                    }
                    color={
                      selectedCoins.indexOf(s) > -1 ? "secondary" : "default"
                    }
                    className="mr-3 mb-2"
                  />
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className="justify-content-between">
          <Button
            startIcon={<Close />}
            variant="contained"
            color="inherit"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

export default Settings;
