import { action } from "typesafe-actions";
import { UserActionTypes } from "./user.model";

export const updateUserRequest = (req) => action(UserActionTypes.UPDATE_USER_REQUEST, req);
export const updateUserSuccess = (res) => action(UserActionTypes.UPDATE_USER_SUCCESS, res);
export const updateUserFailure = (err) => action(UserActionTypes.UPDATE_USER_FAILURE, err);

export const updateKeyRequest = (req) => action(UserActionTypes.UPDATE_KEY_REQUEST, req);
export const updateKeySuccess = (res) => action(UserActionTypes.UPDATE_KEY_SUCCESS, res);
export const updateKeyFailure = (err) => action(UserActionTypes.UPDATE_KEY_FAILURE, err);

export const verifyPinRequest = (req) => action(UserActionTypes.VERIFY_PIN_REQUEST, req);
export const verifyPinSuccess = (res) => action(UserActionTypes.VERIFY_PIN_SUCCESS, res);
export const verifyPinFailure = (err) => action(UserActionTypes.VERIFY_PIN_FAILURE, err);

export const updateTokenRequest = (req) => action(UserActionTypes.UPDATE_TOKEN_REQUEST, req);
export const updateTokenSuccess = (res) => action(UserActionTypes.UPDATE_TOKEN_SUCCESS, res);
export const updateTokenFailure = (err) => action(UserActionTypes.UPDATE_TOKEN_FAILURE, err);

export const getTokenBNBBalRequest = (req) => action(UserActionTypes.GET_TOKEN_BAL_REQUEST, req);
export const getTokenBNBBalSuccess = (res) => action(UserActionTypes.GET_TOKEN_BAL_SUCCESS, res);
export const getTokenBNBBalFailure = (err) => action(UserActionTypes.GET_TOKEN_BAL_FAILURE, err);

export const getStatisticsRequest = () => action(UserActionTypes.GET_STATUS_REQUEST);
export const getStatisticsSuccess = (res) => action(UserActionTypes.GET_STATUS_SUCCESS, res);
export const getStatisticsFailure = (err) => action(UserActionTypes.GET_STATUS_FAILURE, err);

export const fetchCoinBalanceRequest = () => action(UserActionTypes.FETCH_BALANCE_REQUEST);
export const fetchCoinBalanceSuccess = (res) => action(UserActionTypes.FETCH_BALANCE_SUCCESS, res);
export const fetchCoinBalanceFailure = (err) => action(UserActionTypes.FETCH_BALANCE_FAILURE, err);

export const updateSettingsRequest = (req) => action(UserActionTypes.UPDATE_SETTINGS_REQUEST, req);
export const updateSettingsSuccess = (res) => action(UserActionTypes.UPDATE_SETTINGS_SUCCESS, res);
export const updateSettingsFailure = (err) => action(UserActionTypes.UPDATE_SETTINGS_FAILURE, err);

export const logout = () => action(UserActionTypes.USER_LOGOUT);
