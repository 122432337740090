export const API_URL = process.env.REACT_APP_API_URL;

export function getWithdrawUrl(status) {
  return API_URL + "/requests?status=" + status;
}

export function getWebhookSendUrl(id) {
  return API_URL + `/withdraw/hook/${id}`;
}

export function restartTransactionUrl(id) {
    return API_URL + `/withdraw/reinstant/${id}`;
  }
