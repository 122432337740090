export const WithdrawActionTypes = {
    FETCH_WITHDRAW_REQUEST: '@@withdraw/FETCH_WITHDRAW_REQUEST',
    FETCH_WITHDRAW_SUCCESS: '@@withdraw/FETCH_WITHDRAW_SUCCESS',
    FETCH_WITHDRAW_FAILURE: '@@withdraw/FETCH_WITHDRAW_FAILURE',

    RESTART_WITHDRAW_REQUEST: '@@withdraw/RESTART_WITHDRAW_REQUEST',
    RESTART_WITHDRAW_SUCCESS: '@@withdraw/RESTART_WITHDRAW_SUCCESS',
    RESTART_WITHDRAW_FAILURE: '@@withdraw/RESTART_WITHDRAW_FAILURE',
}

export const withdrawInitialState = {
    withdraws: [],
    isLoading: false
}