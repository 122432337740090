import { Animation } from '@devexpress/dx-react-chart';
import {
    Chart,
    Legend,
    PieSeries,
    Title,
} from '@devexpress/dx-react-chart-material-ui';
import * as React from 'react';

export default function DonutChart(props) {
    const { data, field, value, title } = props;
    return (
        <Chart
            data={data}
        >
            <PieSeries
                valueField={value}
                argumentField={field}
                innerRadius={0.6}
            />

            <Legend position='bottom' />
            <p style={{
                position: "absolute",
                top: "30%",
                left: "44%",
                fontSize: 24,
                fontWeight: "bold",
                textAlign: "center"
            }}>{(data.map((s) => s.val).reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0)).toFixed(3)}<br />
                USD</p>
        </Chart>
    );
}
