import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { UserActionTypes } from './user/user.model';
import { UserReducer } from './user/user.reducer';
import { AuthReducer } from './auth/auth.reducer';
import { DepositReducer } from './deposit/deposit.reducer';
import { WithdrawReducer } from './withdraw/withdraw.reducer';
import { TransactionReducer } from './transaction/transaction.reducer';
import { WhitelistReducer } from './whitelist/whitelist.reducer';
import { SupportReducer } from './support/support.reducer';

const appReducer = combineReducers({
    UserReducer,
    AuthReducer,
    DepositReducer,
    WithdrawReducer,
    TransactionReducer,
    WhitelistReducer,
    SupportReducer
});

const persistConfig = {
    key: "bitazure",
    storage: storageSession,
};
const rootReducer = (state, action) => {
    if (action.type === UserActionTypes.USER_LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
