import { WhitelistActionTypes, whitelistInitialState } from './whitelist.model';

const reducer = (state = whitelistInitialState, action) => {
    switch (action.type) {
        case WhitelistActionTypes.FETCH_WHITELIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case WhitelistActionTypes.FETCH_WHITELIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                whitelists: action.payload
            };

        case WhitelistActionTypes.FETCH_WHITELIST_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as WhitelistReducer };