export const API_URL = process.env.REACT_APP_API_URL;

export function updateKeyUrl() {
    return API_URL + '/customers/';
}

export function getUpdatePinUrl(code) {
    return API_URL + '/customers/' + code
}

export function getCheckEmail(email) {
    return API_URL + '/customers/email/' + email
}

export function getStatisticsUrl() {
    return API_URL + '/stats';
}

export function getBalanceUrl() {
    return API_URL + '/members/balance';
}

export function getBNBBalanceUrl(req) {
    return API_URL + '/customers/tokens/' + req.tokenAddress + "?address=" + req.address;
}

export function updateSettingUrl() {
    return API_URL + '/customers/settings';
}

export function getVerifyPinUrl(code, nodata = false) {
    let url = API_URL + '/customers/verify/' + code;
    if (nodata) {
        url = url + "?mode=valid";
    }

    return url;
}