import HttpHelper from "../../helpers/httpHelper";
import { getDepositUrl } from "./deposit.uri";

class DepositAPI {
    static async GetDeposits(status) {
        let url = getDepositUrl(status);
        return HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default DepositAPI;
