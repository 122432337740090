export function getTransactionLoading(state) {
    return state.TransactionReducer.isLoading;
}

export function getTransactions(state) {
    return state.TransactionReducer.transactions;
}

export function getCoinPrices(state) {
    return state.TransactionReducer.prices;
}

export function getOrders(state) {
    return state.TransactionReducer.orders;
}