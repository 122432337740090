import { all } from 'redux-saga/effects';
import { UserSaga } from './user/user.saga';
import { AuthSaga } from './auth/auth.saga';
import { DepositSaga } from './deposit/deposit.saga';
import { WithdrawSaga } from './withdraw/withdraw.saga';
import { WhitelistSaga } from './whitelist/whitelist.saga';
import { TransactionSaga } from './transaction/transaction.saga';
import { SupportSaga } from './support/support.saga';

export default function* rootSaga() {
    yield all([
        AuthSaga(),
        UserSaga(),
        DepositSaga(),
        WithdrawSaga(),
        WhitelistSaga(),
        TransactionSaga(),
        SupportSaga()
    ]);
}