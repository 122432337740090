export const SupportActionTypes = {
    FETCH_SUPPORT_REQUEST: '@@support/FETCH_SUPPORT_REQUEST',
    FETCH_SUPPORT_SUCCESS: '@@support/FETCH_SUPPORT_SUCCESS',
    FETCH_SUPPORT_FAILURE: '@@support/FETCH_SUPPORT_FAILURE',

    CREATE_SUPPORT_REQUEST: '@@support/CREATE_SUPPORT_REQUEST',
    CREATE_SUPPORT_SUCCESS: '@@support/CREATE_SUPPORT_SUCCESS',
    CREATE_SUPPORT_FAILURE: '@@support/CREATE_SUPPORT_FAILURE',
}

export const supportInitialState = {
    supports: [],
    isLoading: false
}