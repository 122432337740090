import { DepositActionTypes, depositInitialState } from './deposit.model';

const reducer = (state = depositInitialState, action) => {
    switch (action.type) {
        case DepositActionTypes.FETCH_DEPOSIT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case DepositActionTypes.FETCH_DEPOSIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deposits: action.payload
            };

        case DepositActionTypes.FETCH_DEPOSIT_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as DepositReducer };