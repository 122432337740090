import {
  AccountCircle,
  Apartment,
  Email,
  Inbox,
  JoinFull,
  Map,
  MobileFriendly,
  Phone,
} from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import moment from "moment/moment";
import MUIDataTable from "mui-datatables";
import { useSelector } from "react-redux";
import { getUserInfo } from "../store/auth/auth.selector";
import { getUser } from "../store/user/user.selector";

function Profile() {
  const { user, userInfo } = useSelector((state) => {
    return {
      user: getUser(state),
      userInfo: getUserInfo(state),
    };
  });

  const columns = ["Email", "User Id", "Address"];

  const data = user.clients ? user.clients.map((t) => {
    return [t.email, t.userId, t.address];
  }) :[];

  const options = {
    selectableRows: false,
  };
  return (
    <>
      <Container maxWidth="md">
        <Card elevation={10}>
          <CardHeader
            avatar={<img src={userInfo.user.photoURL} />}
            subheader="Profile Information"
            title={userInfo.user.displayName}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Email color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={userInfo.user.email}
                    secondary={"Email"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Phone color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={userInfo.user.phoneNumber}
                    secondary={"Phone"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Apartment color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.tenantName}
                    secondary={"Company"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <JoinFull color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={moment(user.createdOn).format("lll")}
                    secondary={"Member since"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircle color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.primaryContactName}
                    secondary={"Primary Contact Person"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Inbox color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.primaryContactEmail}
                    secondary={"Primary Contact Email"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <MobileFriendly color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.primaryContactPhone}
                    secondary={"Primary Contact Phone"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircle color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.secondaryContactName}
                    secondary={"Secondary Contact Person"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Inbox color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.secondaryContactEmail}
                    secondary={"Secondary Contact Email"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={6}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <MobileFriendly color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.secondaryContactPhone}
                    secondary={"Secondary Contact Phone"}
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} sm={12} lg={12} md={12}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Map color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        {user.address}, {user.city}, {user.region},{" "}
                        {user.country}-{user.zipCode}.
                      </>
                    }
                    secondary={"Address"}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className="w-100 justify-content-center">
            {user.documents &&
              user.documents.map((s) => {
                return (
                  <Card variant="outlined">
                    <CardHeader title={s.id} subheader={s.value} />
                    <CardContent className="text-center">
                      <img
                        src={s.url}
                        height={"200"}
                        alt="logo"
                        className="mw-100"
                      />
                    </CardContent>
                  </Card>
                );
              })}
          </CardActions>
        </Card>
      </Container>
      <br/ >
      <br/ >
      <MUIDataTable
        title={"Member List"}
        data={data}
        columns={columns}
        options={options}
      />
   
    </>
  );
}

export default Profile;
