import { FileCopy } from "@mui/icons-material";
import { Avatar, Card, CardHeader } from "@mui/material";
import { useSelector } from "react-redux";
import toastr from "toastr";
import { getUser } from "../store/user/user.selector";
import { getLogo } from "../helpers/utility";

function WalletList(props) {
    const { onChange, coinBalance } = props;

    const { user } = useSelector((state) => {
        return {
            user: getUser(state),
        }
    });

    return (
        <div>
            {
                user.wallets.filter((x) => x.currency !== "BCH").map((s) => {
                    return <Card className="mb-2 cursor" elevation={10} onClick={() => onChange(s.currency)}>
                        <CardHeader
                            title={s.currency}
                            subheader={coinBalance[s.currency] || 0}
                            action={
                                <FileCopy color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(s.address);
                                        toastr.success("Copied Address to clipboard");
                                    }} />
                            }
                            avatar={<Avatar src={getLogo(s.currency).logo} >
                            </Avatar>} />
                    </Card>
                })
            }
        </div>
    );
}

export default WalletList;
