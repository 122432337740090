import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake.min.js";
import { getTransactionUrl, IsNullOrEmpty } from "./utility";

export function generatePdf(
  data,
  title,
  text,
  pageSize = "A3",
  pageOrientation = "landscape",
  currentUser
) {
  var docDefinition = {
    pageSize: pageSize,
    pageOrientation: pageOrientation,
    pageMargins: [40, 60, 40, 60],
    footer: function (currentPage, pageCount) {
      return [
        {
          canvas: [
            { type: "line", x1: 40, y1: 10, x2: 1140, y2: 10, lineWidth: 0.5 },
          ],
        },
        {
          text:
            "Report Generated at " +
            moment().format("lll") +
            " by " +
            currentUser.user.email,
          style: "footerText",
          margin: [40, 10, 20, 0],
        },
        {
          text: currentPage.toString() + " of " + pageCount,
          style: "footer",
          margin: [20, 10, 20, 0],
        },
      ];
    },
    header: function (currentPage, pageCount, pageSize) {
      return [
        { text: title, style: "header", margin: [0, 20, 0, 0] },
        {
          canvas: [
            { type: "line", x1: 40, y1: 0, x2: 1140, y2: 0, lineWidth: 2 },
          ],
        },
      ];
    },
    watermark: {
      text: currentUser.user.displayName + " Confidential",
      opacity: 0.3,
      bold: true,
      italics: false,
      fontSize: 50,
    },
    content: [
      text,
      {
        style: "table",
        heights: 40,
        padding: [10, 20, 10, 20],
        table: {
          body: data,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "gray";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "black" : "gray";
          },
          paddingLeft: function (i, node) {
            return 6;
          },
          paddingRight: function (i, node) {
            return 6;
          },
          paddingTop: function (i, node) {
            return 10;
          },
          paddingBottom: function (i, node) {
            return 10;
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex === 0
              ? "#CECECE"
              : rowIndex % 2 === 0
              ? "#F4F4F4"
              : "#FFFFFF";
          },
        },
      },
    ],
    styles: {
      header: {
        fontSize: 25,
        bold: true,
        alignment: "center",
        lineHeight: 1.2,
      },
      footerText: {
        alignment: "left",
        italics: true,
        bold: true,
        lineHeight: 1,
      },
      footer: {
        fontSize: 12,
        italics: true,
        alignment: "right",
        lineHeight: 1,
      },
      table: {
        margin: [0, 10, 0, 0],
      },
    },
  };
  pdfMake.setFonts({
    Roboto: {
      normal:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
      italics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
      bolditalics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
    },
  });
  pdfMake
    .createPdf(docDefinition)
    .download(title + "_" + moment().unix().toString());
}

export function downloadWithdrawReport(withdraws, user) {
  let data = [];
  data.push([
    "S.No",
    "Customer ID",
    "Customer Name",
    "Customer Mobile",
    "Address",
    "Customer Hash",
    "Admin Hash",
    "Coins",
    "Transaction Charge",
    "Reference No",
    "Created Date",
    "Updated Date",
    "Status",
  ]);
  let cnt = 0;

  withdraws.forEach((item, index) => {
    cnt = cnt + 1;
    let fees = item.transactionChargeFees;
    if (!fees) {
      item.transactionChargeFees = parseFloat(
        item.transactionCharge / item.coinValue
      ).toFixed(8);
    }
    data.push([
      { text: cnt },
      { text: item.cid },
      { text: item.name },
      { text: item.mobile },
      { text: item.address },
      {
        text: !IsNullOrEmpty(item.transactionhash) ? "Hash" : "",
        link: getTransactionUrl(item.type) + item.transactionhash,
      },
      {
        text: !IsNullOrEmpty(item.parentTransactionHash) ? "Hash" : "",
        link: getTransactionUrl(item.type) + item.parentTransactionHash,
      },
      {
        text:
          item.coins + " " + (item.tokenAddress ? item.tokenSymbol : item.type),
        noWrap: true,
      },
      { text: item.transactionChargeFees + " " + item.type, noWrap: true },
      { text: item.txnid, noWrap: true },
      {
        text: moment.unix(item.createdOn._seconds).format("lll"),
        noWrap: true,
      },
      {
        text: moment.unix(item.updatedOn._seconds).format("lll"),
        noWrap: true,
      },
      { text: item.status },
    ]);
  });
  generatePdf(
    data,
    "Withdraw Report for " + moment().format("DD-MM-YYYY"),
    "",
    "A2",
    "landscape",
    user
  );
}
