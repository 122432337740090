import { Box, Button, Card, CardActions, CardContent, CardHeader, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IsNullOrEmpty, clearToken } from '../helpers/utility';
import { getAccessToken } from '../store/auth/auth.selector';
import { getUser } from '../store/user/user.selector';
import { verifyPinRequest } from '../store/user/user.action';
import Loader from '../common/loader';

function VerifyPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');

  const { accessToken, user, isLoading } = useSelector((state) => {
    return {
      accessToken: getAccessToken(state),
      user: getUser(state),
      isLoading: state.UserReducer.isLoading
    }
  });

  useEffect(() => {
    if (IsNullOrEmpty(accessToken)) {
      navigate("/login");
    }
    if (!IsNullOrEmpty(user.id)) {
      navigate("/");
    }
  }, [accessToken, user, navigate])

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
      <Container maxWidth="xs" className='text-center'>
        <img src={process.env.PUBLIC_URL + 'images/splash.png'} alt="splash" width={250} />
        {
          isLoading && <Loader />
        }
        <Card elevation={10}>
          <CardHeader titleTypographyProps={{
            className: "text-center"
          }} title="Verify PIN" />
          <CardContent className='d-flex justify-content-center'>
            <OtpInput
              value={otp}
              inputStyle="otp-input"
              onChange={setOtp}
              inputType='password'
              numInputs={6}
              renderSeparator={<span className="mx-2"></span>}
              renderInput={(props) => <input {...props} />}
            />
          </CardContent>
          <CardActions className='d-flex justify-content-center'>
            <Button onClick={() => dispatch(verifyPinRequest(otp))} variant='contained' disabled={otp.length !== 6}>
              Verify
            </Button>
            <Button onClick={() => clearToken()} variant='contained' color="secondary">
              Signout
            </Button>
          </CardActions>
        </Card>

      </Container>
    </Box>
  );
}

export default VerifyPage;
