export function getUserLoading(state) {
    return state.UserReducer.isLoading;
}

export function getUser(state) {
    return state.UserReducer.user;
}

export function getStats(state) {
    return state.UserReducer.stats;
}

export function getCoinBalance(state) {
    return state.UserReducer.coinBalance;
}
