export const API_URL = process.env.REACT_APP_API_URL;

export function getTransactionUrl(type) {
    return API_URL + '/transactions?type=' + type;
}

export function getTokenBalanceUrl() {
    return API_URL + '/bnb/token/metadata';
}

export function updateTokensUrl() {
    return API_URL + '/customers';
}

export function getPriceAPIUrl() {
    return API_URL + '/coin/price';
}

export function getTransferCoinUrl(address, type, coins) {
    return API_URL + `/transaction/${address}/send?type=${type}&coins=${coins}`;
}

export function getTransferTokensUrl(address, type, coins, tokenAddress) {
    return API_URL + `/transaction/${address}/send?type=${type}&coins=${coins}&tokenAddress=${tokenAddress}`;
}

export function createOrderUrl() {
    return API_URL + `/transaction/order`;
}


