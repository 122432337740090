import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import Swal from "sweetalert2";
import UserAPI from "../store/user/user.api";
import Loader from "./loader";
import { isVerifiedUser } from "../store/auth/auth.selector";
import { useSelector } from "react-redux";

const VerifyPin = (props) => {
    const { onClose, onComplete, id } = props;
    const [otp, setOtp] = useState('');
    const [isLoading, setLoading] = useState(false);

    const { verifiedUser } = useSelector((state) => {
        return {
            verifiedUser: isVerifiedUser(state)
        }
    })

    useEffect(() => {
        if (!verifiedUser) {
            document.location.href = "/onboarding";
        }
    }, [verifiedUser])

    const handleConfirm = () => {
        setLoading(true);
        UserAPI.VerifyPin(otp, true).then((x) => {
            onComplete(id);
        }).catch(() => {
            Swal.fire({
                icon: "error",
                title: "Invalid PIN",
                text: "Please enter the correct PIN",
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Close"
            }).then((x) => {
                if (x.isDismissed) {
                    onClose();
                }
            })
        }).finally(() => {
            setLoading(false);
        })

    }
    return (
        <Dialog maxWidth="md" open>
            <DialogContent>
                {
                    isLoading && <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                        <CircularProgress />
                        <p className="text-primary">Loading...</p>
                    </Box>
                }
                <Card elevation={0}>
                    <CardHeader titleTypographyProps={{
                        className: "text-center"
                    }} title="Verify PIN" />
                    <CardContent className='d-flex justify-content-center'>
                        <OTPInput
                            value={otp}
                            inputStyle="otp-input"
                            onChange={setOtp}
                            inputType='password'
                            numInputs={6}
                            renderSeparator={<span className="mr-1"></span>}
                            renderInput={(props) => <input {...props} />}
                        />
                    </CardContent>
                    <CardActions className='d-flex justify-content-center'>
                        <Button onClick={() => handleConfirm()} variant='contained' disabled={otp.length !== 6}>
                            Verify
                        </Button>
                        <Button onClick={() => onClose()} variant='contained' color="secondary">
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

export default VerifyPin;
