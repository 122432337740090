import { SupportActionTypes, supportInitialState } from './support.model';

const reducer = (state = supportInitialState, action) => {
    switch (action.type) {
        case SupportActionTypes.FETCH_SUPPORT_REQUEST:
        case SupportActionTypes.CREATE_SUPPORT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case SupportActionTypes.FETCH_SUPPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                supports: action.payload
            };

        case SupportActionTypes.CREATE_SUPPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                supports: [action.payload, ...state.supports]
            };

        case SupportActionTypes.FETCH_SUPPORT_FAILURE:
        case SupportActionTypes.CREATE_SUPPORT_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as SupportReducer };