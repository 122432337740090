import { AccountBalance, AccountBalanceWallet, CalendarToday, CalendarViewDay, Close, Description, Email, FileCopy, FilterList, Fingerprint, OpenInNew, Output, Search, Visibility } from "@mui/icons-material";
import { Avatar, Box, Card, CardContent, CardHeader, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Select, TextField } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import _ from "underscore";
import Loader from "../common/loader";
import { IsNullOrEmpty, getBlockChainUrl, getLogo, getTransactionUrl, pageSize, paginate } from "../helpers/utility";
import { fetchTransactionRequest } from "../store/transaction/transaction.action";
import { getTransactions } from "../store/transaction/transaction.selector";

function Transaction() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("BNB");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [datum, setDatum] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (sta) => {
    if (sta) {
      setStatus(sta);
    }
    setAnchorEl(null);
  };

  const { transactions, isLoading } = useSelector((state) => {
    return {
      transactions: getTransactions(state),
      isLoading: state.TransactionReducer.isLoading
    }
  });

  useEffect(() => {
    if (selectedItem) {
      let z = _.find(transactions, x => x.id === selectedItem);
      setDatum(z);
    }
    else {
      setDatum({});
    }
  }, [selectedItem])

  useEffect(() => {
    if (status) {
      dispatch(fetchTransactionRequest(status));
    }
  }, [status]);

  let filteredList = transactions;
  if (!filteredList) {
    filteredList = [];
  }

  if (!IsNullOrEmpty(searchText)) {
    filteredList = filteredList.filter((x) =>
      x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      x.cid.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      x.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      x.txnid.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      x.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
  }

  if (!IsNullOrEmpty(filter)) {
    let startTime = moment().startOf('day');
    let endTime = moment().endOf('day');
    switch (filter) {
      case "Today":
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
        })
        break;
      case "Week":
        startTime = moment().startOf("weeks");
        endTime = moment().endOf('weeks');
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
        })
        break;
      case "Month":
        startTime = moment().startOf('month');
        endTime = moment().endOf('month');
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
        })
        break;
      case "Year":
        startTime = moment().startOf('year');
        endTime = moment().endOf('year');
        filteredList = filteredList.filter((x) => {
          return moment.unix(x.createdOn._seconds).isBetween(startTime, endTime)
        })
        break;
    }
  }

  filteredList = _.sortBy(filteredList, "createdOn").reverse();
  let totalPages = Math.ceil(filteredList.length / pageSize);
  filteredList = paginate(filteredList, pageSize, page);
  return (
    <div>
      <h2 className="py-0 my-0">Transactions</h2>
      {
        isLoading && <Loader />
      }
      <Divider />
      {
        datum.id &&
        <Dialog maxWidth="md" fullWidth open={datum.id}>
          <DialogTitle>
            Transaction Information
            <Close className="float-right mt-2" onClick={() => {
              setDatum({});
            }} />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Email />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Customer Email' primary={datum.email} />
                    {
                      navigator && navigator.clipboard &&
                      <ListItemSecondaryAction>
                        <FileCopy color="secondary" onClick={() => {
                          navigator.clipboard.writeText(datum.email);
                          toastr.success("Copied Address to clipboard");
                        }} />
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Output />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Status' primary={datum.status} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBalance />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary={`Transaction ${datum.tokenSymbol ? "Tokens" : "Coins"}`} primary={`${datum.coins} ${datum.tokenSymbol || datum.type}`} />
                    {
                      navigator && navigator.clipboard &&
                      <ListItemSecondaryAction>
                        <FileCopy color="secondary" onClick={() => {
                          navigator.clipboard.writeText(datum.coins);
                          toastr.success("Copied Address to clipboard");
                        }} />
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBalanceWallet />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Transaction Charge' primary={`${datum.transactionCharge.toFixed(8)} ${datum.tokenSymbol || datum.type}`} />
                    {
                      navigator && navigator.clipboard &&
                      <ListItemSecondaryAction>
                        <FileCopy color="secondary" onClick={() => {
                          navigator.clipboard.writeText(datum.transactionCharge);
                          toastr.success("Copied Address to clipboard");
                        }} />
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Transaction ID' primary={datum.id} />
                    {
                      navigator && navigator.clipboard &&
                      <ListItemSecondaryAction>
                        <FileCopy color="secondary" onClick={() => {
                          navigator.clipboard.writeText(datum.id);
                          toastr.success("Copied Address to clipboard");
                        }} />
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Description />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Reference #' primary={datum.txnid} />
                    {
                      navigator && navigator.clipboard &&
                      <ListItemSecondaryAction>
                        <FileCopy color="secondary" onClick={() => {
                          navigator.clipboard.writeText(datum.txnid);
                          toastr.success("Copied Address to clipboard");
                        }} />
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>


              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Created On' primary={moment.unix(datum.createdOn._seconds).format("lll")} />
                    {
                      <ListItemSecondaryAction>
                        {moment.unix(datum.createdOn._seconds).fromNow()}
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarViewDay />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Last Modified On' primary={moment.unix(datum.updatedOn._seconds).format("lll")} />
                    {
                      <ListItemSecondaryAction>
                        {moment.unix(datum.updatedOn._seconds).fromNow()}
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Transaction Hash' primary={datum.transactionhash} />
                    {
                      datum && datum.transactionhash &&
                      <ListItemSecondaryAction>
                        <a target='_blank' href={getTransactionUrl(datum.type) + datum.transactionhash}>
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary='Transaction Charge Hash' primary={datum.parentTransactionHash} />
                    {
                      datum && datum.parentTransactionHash &&
                      <ListItemSecondaryAction>
                        <a target='_blank' href={getTransactionUrl(datum.type) + datum.parentTransactionHash}>
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      }
      <Grid container spacing={2} className="my-2">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display={"flex"} width="100%" justifyContent={"space-between"} alignContent={"center"}>
            <div>
              <TextField
                variant="outlined"
                className="rounded-lg"
                style={{ marginTop: 0 }}
                margin="dense"
                size="small"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }}
                placeholder="Search..."
              />
              <FilterList
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="m-1"
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => handleClose("BNB")}>BNB</MenuItem>
                <MenuItem onClick={() => handleClose("TRX")}>TRX</MenuItem>
                <MenuItem onClick={() => handleClose("BTC")}>BTC</MenuItem>
                <MenuItem onClick={() => handleClose("XRP")}>XRP</MenuItem>
                <MenuItem onClick={() => handleClose("LTC")}>LTC</MenuItem>
                <MenuItem onClick={() => handleClose("ETH")}>ETH</MenuItem>
                <MenuItem onClick={() => handleClose("BCH")}>BCH</MenuItem>
                <MenuItem onClick={() => handleClose("BUSD")}>BUSD</MenuItem>
                <MenuItem onClick={() => handleClose("USDT")}>USDT</MenuItem>
              </Menu>
              <Chip label={status} variant="filled" color="primary" />
            </div>
            <Select
              native
              margin="dense"
              size="small"
              variant="outlined"
              value={filter}
              onChange={(e) => [
                setFilter(e.target.value)
              ]}
            >
              <option value="">All Time</option>
              <option value="Today">Today</option>
              <option value="Week">This Week</option>
              <option value="Month">This Month</option>
              <option value="Year">This Year</option>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2}>
            {
              filteredList.map((dep) => {
                return <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card variant="outlined" style={{ opacity: dep.status === "Approved" ? 1 : 0.7 }}>
                    <CardHeader
                      avatar={<img style={{ height: 32, width: 32 }}
                        src={getLogo(dep.type).logo} />}
                      classes={{
                        root: "p-1",
                        action: "px-3 py-2"
                      }}
                      title={dep.status}
                      subheader={`${dep.amount} USD`}
                      action={
                        <Visibility
                          className="cursor"
                          color="info"
                          onClick={() => {
                            setDatum(dep);
                          }}
                        />
                      }
                      style={{ background: dep.status === "Approved" ? "#bcf5bc" : "inherit" }}
                    />
                    <Divider />
                    <CardContent className="p-0">
                      <table className="table-striped text-medium border-none">
                        <tr>
                          <td>Transfered {dep.tokenSymbol ? "Tokens" : "Coins"}</td>
                          <td >
                            {
                              dep.transactionhash ?
                                <a className="text-primary font-weight-bolder text-decoration-none" target="_blank" href={getBlockChainUrl(dep.type) + dep.transactionhash}>{`${dep.coins} ${dep.tokenSymbol || dep.type}`}</a> :
                                `${dep.coins} ${dep.tokenSymbol || dep.type}`
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Transaction Charge</td>
                          <td>
                            {
                              dep.parentTransactionHash ?
                                <a className="text-info font-weight-bolder text-decoration-none" target="_blank" href={getTransactionUrl(dep.type) + dep.parentTransactionHash}>{`${dep.transactionCharge.toFixed(8)} ${dep.type}`}</a> :
                                `${dep.transactionCharge.toFixed(8)} ${dep.type}`
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Transaction Time</td>
                          <td>{moment.unix(dep.createdOn._seconds).format("lll")}</td>
                        </tr>
                      </table>
                    </CardContent>
                  </Card>
                </Grid>
              })
            }
          </Grid>
        </Grid>
        <Grid xs={12} sm={12}>
          {
            filteredList.length > 0 &&
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} className="my-2">
              <Pagination
                shape="rounded"
                variant="outlined"
                count={totalPages}
                page={page}
                color="primary"
                onChange={(e, pg) => {
                  setPage(pg)
                }} />
            </Box>
          }
          {
            filteredList.length === 0 && !isLoading && <p className="text-center">No records available</p>
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default Transaction;
