import { action } from "typesafe-actions";
import { SupportActionTypes } from "./support.model";

export const fetchSupportRequest = (req) => action(SupportActionTypes.FETCH_SUPPORT_REQUEST, req);
export const fetchSupportSuccess = (res) => action(SupportActionTypes.FETCH_SUPPORT_SUCCESS, res);
export const fetchSupportFailure = (err) => action(SupportActionTypes.FETCH_SUPPORT_FAILURE, err);

export const createSupportRequest = (req) => action(SupportActionTypes.CREATE_SUPPORT_REQUEST, req);
export const createSupportSuccess = (res) => action(SupportActionTypes.CREATE_SUPPORT_SUCCESS, res);
export const createSupportFailure = (err) => action(SupportActionTypes.CREATE_SUPPORT_FAILURE, err);
