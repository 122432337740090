export function getAuthLoading(state) {
    return state.AuthReducer.isLoading;
}

export function getUserInfo(state) {
    return state.AuthReducer.auth;
}

export function getAccessToken(state) {
    return state &&
        state.AuthReducer &&
        state.AuthReducer.auth &&
        state.AuthReducer.auth._tokenResponse &&
        state.AuthReducer.auth._tokenResponse.idToken;
}

export function isVerifiedUser(state) {
    return state &&
        state.AuthReducer &&
        state.AuthReducer.auth &&
        state.AuthReducer.auth.user &&
        state.AuthReducer.auth.user.phoneNumber;
}

