import { AuthActionTypes, authInitialState } from './auth.model';

const reducer = (state = authInitialState, action) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case AuthActionTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                auth: action.payload
            };

        case AuthActionTypes.LOGIN_USER_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as AuthReducer };