import HttpHelper from "../../helpers/httpHelper";
import { getSupportUrl, createSupportUrl } from "./support.uri";

class SupportAPI {
    static async GetSupports(status) {
        let url = getSupportUrl(status);
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async CreateSupport(req) {
        let url = createSupportUrl();
        return HttpHelper.httpRequest(url, 'POST', {}, req);
    }
}

export default SupportAPI;
