import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { clearToken } from "../helpers/utility";
import { isVerifiedUser } from "../store/auth/auth.selector";
import { logout } from "../store/user/user.action";
import UserAPI from "../store/user/user.api";

const SetPin = (props) => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState('');
    const [cotp, setCOtp] = useState('');
    const [checkPin, setCheckPin] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const { verifiedUser } = useSelector((state) => {
        return {
            verifiedUser: isVerifiedUser(state)
        }
    })

    useEffect(() => {
        if (verifiedUser) {
            document.location.href = "/verify";
        }
    }, [verifiedUser])

    const handleConfirm = () => {
        setLoading(true);
        UserAPI.UpdatePin(otp).then((x) => {
            Swal.fire({
                icon: "success",
                title: "Onboarding Success",
                text: "Please Login again",
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Close"
            }).then((x) => {
                if (x.isDismissed) {
                    dispatch(logout());
                    clearToken();
                }
            })
        }).catch(() => {
            Swal.fire({
                icon: "error",
                title: "Something went wrong",
                text: "Please try again",
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Close"
            })
        }).finally(() => {
            setLoading(false);
        })

    }
    return (
        <div className='text-center'>
            <Container maxWidth="sm">
                {
                    isLoading && <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                        <CircularProgress />
                        <p className="text-primary">Onboarding...</p>
                    </Box>
                }
                <img src={process.env.PUBLIC_URL + 'images/splash.png'} alt="splash" className='w-100' />
                {
                    !isLoading && <Card elevation={0}>
                        <CardHeader titleTypographyProps={{
                            className: "text-center"
                        }} title={checkPin ? "Confirm Pin" : "Set Pin"} />
                        <CardContent className='d-flex justify-content-center'>
                            {
                                checkPin ? <OTPInput
                                    value={cotp}
                                    inputStyle="otp-input"
                                    onChange={setCOtp}
                                    inputType='password'
                                    numInputs={6}
                                    renderSeparator={<span className="mr-1"></span>}
                                    renderInput={(props) => <input {...props} />}
                                /> :
                                    <OTPInput
                                        value={otp}
                                        inputStyle="otp-input"
                                        onChange={setOtp}
                                        inputType='password'
                                        numInputs={6}
                                        renderSeparator={<span className="mr-1"></span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                            }

                        </CardContent>
                        {
                            checkPin ? <CardActions className='d-flex justify-content-center'>
                                <Button onClick={() => handleConfirm()} variant='contained' disabled={
                                    cotp.length !== 6 ||
                                    otp.length !== 6}>
                                    Set Pin
                                </Button>
                                <Button onClick={() => {
                                    dispatch(logout());
                                    clearToken();
                                }} variant='contained' color="secondary">
                                    Cancel
                                </Button>
                            </CardActions> :
                                <CardActions className='d-flex justify-content-center'>
                                    <Button onClick={() => setCheckPin(true)} variant='contained' disabled={
                                        otp.length !== 6}>
                                        Next
                                    </Button>
                                    <Button onClick={() => {
                                        dispatch(logout());
                                        clearToken();
                                    }} variant='contained' color="secondary">
                                        Cancel
                                    </Button>
                                </CardActions>
                        }
                    </Card>
                }

            </Container>
        </div>
    );
};

export default SetPin;
