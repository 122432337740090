import HttpHelper from "../../helpers/httpHelper";
import { updateTokensUrl } from "../transaction/transaction.uri";
import { getBNBBalanceUrl, getBalanceUrl, getCheckEmail, getStatisticsUrl, getUpdatePinUrl, getVerifyPinUrl, updateKeyUrl, updateSettingUrl } from "./user.uri";

class UserAPI {
    static async GenerateNewKey() {
        let url = updateKeyUrl("");
        return HttpHelper.httpRequest(url, 'PUT', {});
    }

    static UpdatePin(code) {
        let url = getUpdatePinUrl(code)
        return HttpHelper.httpRequest(url, 'PUT', {});
    }

    static async UpdateTokens(tokenInfo) {
        let url = updateTokensUrl();
        return HttpHelper.httpRequest(url, 'PATCH', {}, tokenInfo);
    }

    static CheckEmail(code) {
        let url = getCheckEmail(code)
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async VerifyPin(code, nodata = false) {
        let url = getVerifyPinUrl(code, nodata);
        return await HttpHelper.httpRequest(url, 'GET', {});
    }

    static async GetStats() {
        let url = getStatisticsUrl();
        return await HttpHelper.httpRequest(url, 'GET', {});
    }

    static async GetCoinBalnce(wallet) {
        let url = getBalanceUrl();
        return await HttpHelper.httpRequest(url, 'GET', {});
    }

    static async UpdateSetting(req) {
        let url = updateSettingUrl();
        return await HttpHelper.httpRequest(url, 'PATCH', {}, req);
    }

    static async GetBNBWalletAddressBal(req) {
        let url = getBNBBalanceUrl(req);
        return await HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default UserAPI;
