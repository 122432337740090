import { BrowserUpdated, CallMadeRounded, CallReceivedRounded, Close, ExpandMore, FileCopy, Language, SwapHoriz, SyncAlt, VerticalAlignBottom, VerticalAlignTop } from "@mui/icons-material";
import { Avatar, Box, Button, Card, Menu, MenuItem, CardContent, CardHeader, Container, Divider, Grid, InputAdornment, TextField, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { QRCode } from 'react-qrcode';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import toastr from "toastr";
import _ from "underscore";
import VerifyPin from "../common/verifyPin";
import { IsNullOrEmpty, calculateData, getBlockChainUrl, getLogo } from "../helpers/utility";
import { getUser } from "../store/user/user.selector";
import { createOrderRequest, transferCoinRequest } from "../store/transaction/transaction.action";
import { getCoinPrices } from "../store/transaction/transaction.selector";
export const pageSize = 9;


function WalletCard(props) {
    const { coinBalance, currentBalance, onClose, selectedItem } = props;
    const dispatch = useDispatch();
    const [mode, setMode] = useState("");
    const [toAddress, setAddress] = useState(null);
    const [toAmount, setToAmount] = useState(null);
    const [open, setOpen] = useState(false);
    const [sourceCoin, setSourceCoin] = useState("USDT");
    const [qty, setQty] = useState(1);
    const [amount, setAmount] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (it) => {
        setAnchorEl(null);
        setSourceCoin(it);
    };


    const { user, prices } = useSelector((state) => {
        return {
            user: getUser(state),
            prices: getCoinPrices(state)
        }
    });

    let isDisabled = IsNullOrEmpty(toAddress) ||
        IsNullOrEmpty(toAmount) ||
        isNaN(parseFloat(toAmount)) ||
        parseFloat(toAmount) > parseFloat(currentBalance);

    let isBuyDisabled = IsNullOrEmpty(qty) || qty <= 0 || parseFloat(coinBalance[selectedItem] || 0) < parseFloat(qty);


    function handleSend() {
        if (!isDisabled) {
            if (parseFloat(toAmount) < parseFloat(currentBalance)) {
                setOpen(true);
            }
            else {
                Swal.fire({
                    title: "Validation",
                    text: "Insufficient Balance to transfer",
                    icon: "error"
                });
            }
        }
    }

    function handleOrder() {
        if (!isBuyDisabled) {
            setOpen(true);
        }
    }

    if (open) {
        return <VerifyPin
            id={user.id}
            onClose={() => {
                setOpen(false);
            }}
            onComplete={(ref) => {
                if (mode === "Send") {
                    dispatch(transferCoinRequest({
                        address: toAddress,
                        coins: toAmount,
                        type: selectedItem
                    }))
                }

                if (mode === "Buy" || mode === "Sell") {
                    dispatch(createOrderRequest({
                        fromCoin: selectedItem,
                        toCoin: sourceCoin,
                        qty: qty,
                        mode: mode
                    }))
                }

                setOpen(false);
            }} />
    }

    let item = _.find(user.wallets, x => x.currency === selectedItem);
    let coinPrices = calculateData(prices, coinBalance, true);
    let coinData = _.find(coinPrices, x => x.coin === selectedItem);
    let sourceCoinData = _.find(coinPrices, x => x.coin === sourceCoin);

    let amt = ((coinData && coinData.price) * qty / (sourceCoinData && sourceCoinData.price)).toFixed(8);

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card variant="outlined">
                    <CardHeader
                        title={selectedItem + "wallet"}
                        subheader={"Wallet Information"}
                        avatar={<Avatar src={getLogo(selectedItem).logo}>
                        </Avatar>}
                        action={<Close className="cursor" onClick={() => onClose("")} />}
                    />
                    <Divider />
                    <CardContent>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                            <img className="rounded-lg" style={{ height: 128, width: 128 }} src={getLogo(selectedItem).logo} />
                            <p className="text-primary my-1 py-1">  {coinBalance[selectedItem] || 0}
                                &nbsp;{selectedItem}
                            </p>
                            <p className="text-secondary my-1 py-1">
                                {coinData && coinData.val} USD
                            </p>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <p className="text-center text-sm">
                                    <Avatar className="mx-3 cursor" onClick={() => setMode("Send")}>
                                        <CallMadeRounded htmlColor={mode === "Send" ? "red" : "inherit"} />
                                    </Avatar>
                                    Send
                                </p>
                                <p className="text-center">
                                    <Avatar className="mx-3 cursor" onClick={() => setMode("Receive")}>
                                        <CallReceivedRounded htmlColor={mode === "Receive" ? "red" : "inherit"} />
                                    </Avatar>
                                    Receive
                                </p>
                                <p className="text-center">
                                    <Avatar className="mx-3 cursor">
                                        <FileCopy onClick={() => {
                                            navigator.clipboard.writeText(item.address);
                                            toastr.success("Copied Address to clipboard");
                                        }} />
                                    </Avatar>
                                    Copy
                                </p>
                                <p className="text-center">
                                    <Avatar className="mx-3 cursor" onClick={() => setMode("Buy")}>
                                        <SwapHoriz htmlColor={mode === "Buy" ? "red" : "inherit"} />
                                    </Avatar>
                                    Swap
                                </p>
                                <p className="text-center">
                                    <a target="_blank" className="text-decoration-none" href={getBlockChainUrl(item.currency) + item.address}>
                                        <Avatar className="mx-3 cursor">
                                            <Language />
                                        </Avatar>
                                        Explore
                                    </a>
                                </p>
                            </Box>
                            <Box display={"flex"} width="100%" alignItems={"center"} justifyContent={"center"} >
                                {
                                    mode === "Receive" &&
                                    <Container maxWidth="sm" className="text-center">
                                        <div style={{ border: "1px solid #CECECE", display: "inline-block" }}>
                                            <QRCode value={item.address} margin={2} scale={6} />
                                        </div>
                                        <br />
                                        <TextField
                                            className="mt-4"
                                            fullWidth
                                            value={item.address}
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <FileCopy onClick={() => {
                                                        navigator.clipboard.writeText(item.address);
                                                        toastr.success("Copied Address to clipboard");
                                                    }} />
                                                </InputAdornment>
                                            }}
                                        />
                                    </Container>
                                }
                                {
                                    mode === "Send" &&
                                    <Container maxWidth="sm" className="text-center">
                                        <TextField
                                            label="Address"
                                            fullWidth
                                            value={toAddress}
                                            size="small"
                                            margin="dense"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setAddress(e.target.value)
                                            }}
                                            className="my-2"
                                        />
                                        <TextField
                                            label="Coins"
                                            fullWidth
                                            value={toAmount}
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setToAmount(e.target.value)
                                            }}
                                            className="my-2"
                                        />
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Button variant="contained" color="primary" onClick={() => {
                                                setMode("");
                                            }}>
                                                Cancel
                                            </Button>
                                            <Button variant="contained" color="secondary" disabled={isDisabled} onClick={() => {
                                                handleSend()
                                            }}>
                                                Send
                                            </Button>
                                        </Box>

                                    </Container>
                                }
                            </Box>
                            {
                                (mode === "Buy" || mode === "Sell") &&
                                <div className="w-100">
                                    <Container maxWidth="sm" fullWidth className="text-center">
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <List disablePadding>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(selectedItem).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={selectedItem} secondary={coinData && coinData.price + " USD"} />
                                                </ListItem>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(selectedItem).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={selectedItem} secondary={qty + " " + selectedItem} />
                                                </ListItem>
                                            </List>
                                            <SyncAlt />
                                            <List disablePadding>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(sourceCoin).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={sourceCoin} secondary={parseFloat((sourceCoinData && sourceCoinData.price) || 0).toFixed(8) + " USD"} />
                                                </ListItem>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(sourceCoin).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={sourceCoin} secondary={amt + " " + sourceCoin} />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    </Container>
                                </div>
                            }
                            {
                                (mode === "Buy" || mode === "Sell") &&
                                <Container maxWidth="xs" fullWidth className="text-center">
                                    <TextField
                                        fullWidth
                                        value={qty}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => {
                                            setQty(e.target.value)
                                        }}
                                        type="number"
                                        inputProps={{
                                            style: {
                                                textAlign: "right"
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                Amount
                                            </InputAdornment>,
                                            endAdornment: <InputAdornment position="end">
                                                <Button style={{ minWidth: 100 }} onClick={handleClick}>
                                                    {selectedItem}&nbsp;<ExpandMore className="invisible" />
                                                </Button>

                                            </InputAdornment>
                                        }}
                                        className="my-2"
                                    />
                                    <TextField
                                        fullWidth
                                        value={amt}
                                        disabled
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setAmount(e.target.value)
                                        }}
                                        type="number"
                                        inputProps={{
                                            style: {
                                                textAlign: "right"
                                            },
                                            "aria-readonly": "true"
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                Price
                                            </InputAdornment>,
                                            endAdornment: <InputAdornment position="end">
                                                <Button style={{ minWidth: 100 }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                                    {sourceCoin}&nbsp;<ExpandMore />
                                                </Button>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => handleClose("USDT")}
                                                >
                                                    <MenuItem onClick={() => handleClose("BTC")}>BTC</MenuItem>
                                                    <MenuItem onClick={() => handleClose("BNB")}>BNB</MenuItem>
                                                    <MenuItem onClick={() => handleClose("USDT")}>USDT</MenuItem>
                                                    <MenuItem onClick={() => handleClose("BUSD")}>BUSD</MenuItem>
                                                    <MenuItem onClick={() => handleClose("XRP")}>XRP</MenuItem>
                                                    <MenuItem onClick={() => handleClose("LTC")}>LTC</MenuItem>
                                                    <MenuItem onClick={() => handleClose("ETH")}>ETH</MenuItem>
                                                </Menu>
                                            </InputAdornment>
                                        }}
                                        className="my-2"
                                    />
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            setMode("");
                                        }}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" color="secondary" disabled={isBuyDisabled} onClick={() => {
                                            handleOrder()
                                        }}>
                                            {mode} {selectedItem}
                                        </Button>
                                    </Box>
                                </Container>
                            }
                        </Box>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </div>
}

export default WalletCard;
